import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"

import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { scaleValue } from "../util/util"
import SocialLinks from "./sociallinks"
import { useLocation } from "@reach/router"
import { createUseStyles } from "react-jss"
import { Slant as Hamburger } from "hamburger-react"
import Fade from "react-reveal/Fade"
import Cta from "./cta"

const useStyles = createUseStyles({
  header: {
    padding: props => `${props.padding}vw 4vw`,
    backgroundColor: `rgb(246, 245, 234)`,
    position: `fixed`,
    top: 0,
    zIndex: 2,
    width: `100%`,
    boxSizing: "border-box",
  },
  fullMenu: {
    width: `auto`,
    alignSelf: `flex-start`,
    display: `flex`,
    alignItems: `center`,
  },
  smallMenu: {
    display: `none`,
  },
  overlay: {
    position: `fixed`,
    top: `100vh`,
    zIndex: 1,
    width: `100%`,
    backgroundColor: `rgb(246, 245, 234)`,
    paddingTop: props => `${props.height}px`,
    height: `calc(100vh)`,
    opacity: 0,

    animation: props => props.shouldFadeOut && `$fadeOut 0.4s`,

    // transition: `opacity 0.4s ease-out, top 0.4s ease-out`,
  },
  overlayOpen: {
    animation: `$fadeIn 0.4s`,
    composes: `$overlay`,
    paddingTop: props => `${props.height}px`,
    top: 0,
    opacity: 1,
  },
  overlayLinks: {
    textDecoration: `none`,
    color: `black`,
    fontSize: `8.5vmin`,
    padding: `3vw 5vw`,
  },
  "@media (max-width: 1024px)": {
    fullMenu: {
      display: `none`,
    },
    smallMenu: {
      display: `block`,
    },
    header: {
      padding: props => `${props.padding * 2}vw 4vw`,
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
      top: 0,
    },
    to: {
      opacity: 1,
      top: 0,
    },
  },
  "@keyframes fadeOut": {
    from: {
      opacity: 1,
      top: 0,
    },
    to: {
      opacity: 0,
      top: 0,
    },
  },
})

interface Props {
  siteTitle: string
  setHeaderHeight?: React.Dispatch<React.SetStateAction<number>>
}

const Header: React.FC<Props> = ({ siteTitle, setHeaderHeight }) => {
  const [padding, setPadding] = useState(2)
  const [localHeight, setLocalHeight] = useState(0)
  const [shouldFadeOut, setShouldFadeOut] = useState(false)
  const [isBurgerOpen, setBurgerOpen] = useState(false)
  const classes = useStyles({ height: localHeight, shouldFadeOut, padding })
  const { pathname } = useLocation()

  useEffect(() => {
    if (isBurgerOpen) {
      setShouldFadeOut(true)
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }

    return () => {
      document.documentElement.style.overflowY = "scroll"
    }
  }, [isBurgerOpen])

  const headerRef = useRef<HTMLDivElement | null>(null)

  const underlineStyles: React.CSSProperties = {
    textDecoration: `underline`,
  }

  useEffect(() => {
    const handleResize = () => {
      const newHeight = headerRef.current?.getBoundingClientRect().height ?? 0
      if (newHeight != 0 && setHeaderHeight != null) {
        setHeaderHeight(newHeight)
        setLocalHeight(newHeight)
      }
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useScrollPosition(({ currPos }) => {
    const newPadding = scaleValue(currPos.y, [-50, 80], [1, 2])
    setPadding(newPadding)
  })

  const overlay = (
    <div className={isBurgerOpen ? classes.overlayOpen : classes.overlay}>
      <Fade bottom cascade duration={400} when={isBurgerOpen}>
        <div
          style={{
            height: `65%`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `center`,
            flexGrow: 1,
          }}
        >
          <Link
            to="/cabin"
            className={classes.overlayLinks}
            style={{
              ...(pathname == "/cabin" && underlineStyles),
            }}
          >
            The Cabin
          </Link>
          <Link
            to="/gallery"
            className={classes.overlayLinks}
            style={{
              ...(pathname == "/gallery" && underlineStyles),
            }}
          >
            Gallery
          </Link>
          <Link
            to="/discover"
            className={classes.overlayLinks}
            style={{
              ...(pathname == "/discover" && underlineStyles),
            }}
          >
            Discover
          </Link>
          <Link
            to="/reviews"
            className={classes.overlayLinks}
            style={{
              ...(pathname == "/reviews" && underlineStyles),
            }}
          >
            Notes
          </Link>
          <Link
            to="/story"
            className={classes.overlayLinks}
            style={{
              ...(pathname == "/story" && underlineStyles),
            }}
          >
            Our Story
          </Link>
        </div>
        <div>
          <div
            style={{
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              marginBottom: `6vw`,
            }}
          >
            <SocialLinks size={28} isOverlay />
          </div>
        </div>
        <div>
          <div
            style={{
              fontSize: `1.2rem`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              margin: `4vw 6vw 6vw`,
            }}
          >
            <Cta />
          </div>
        </div>
      </Fade>
    </div>
  )

  return (
    <>
      <header className={classes.header} ref={headerRef}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: `space-between`,
          }}
        >
          <h1
            style={{
              fontSize: `calc((2 - 1) * 1.2vh + 1rem)`,
            }}
          >
            <Link
              to="/"
              style={{
                textDecoration: `none`,
                fontFamily: "middle earth",
                color: `inherit`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>

          <div className={classes.smallMenu}>
            <Hamburger toggled={isBurgerOpen} toggle={setBurgerOpen} />
          </div>

          <div className={classes.fullMenu}>
            <nav style={{ marginLeft: "auto" }}>
              <Link
                to="/cabin"
                style={{
                  marginLeft: `1.6vw`,
                  ...(pathname == "/cabin" && underlineStyles),
                }}
              >
                The Cabin
              </Link>
              <Link
                to="/gallery"
                style={{
                  marginLeft: `1.6vw`,
                  ...(pathname == "/gallery" && underlineStyles),
                }}
              >
                Gallery
              </Link>
              <Link
                to="/discover"
                style={{
                  marginLeft: `1.6vw`,
                  ...(pathname == "/discover" && underlineStyles),
                }}
              >
                Discover
              </Link>
              <Link
                to="/reviews"
                style={{
                  marginLeft: `1.6vw`,
                  ...(pathname == "/reviews" && underlineStyles),
                }}
              >
                Notes
              </Link>
              <Link
                to="/story"
                style={{
                  marginLeft: `1.6vw`,
                  ...(pathname == "/story" && underlineStyles),
                }}
              >
                Our Story
              </Link>
              {/* <Link to="/" style={{ marginLeft: `1.6vw` }}>
            Contact
          </Link> */}
            </nav>
            <div style={{ position: "relative", top: 2, marginLeft: `1.6vw` }}>
              <SocialLinks />
            </div>
            <Cta marginLeft="1.6vw" />
          </div>
        </div>
      </header>
      {overlay}
    </>
  )
}

export default Header
