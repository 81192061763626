import React from "react"
import { createUseStyles } from "react-jss"
import SocialLinks from "./sociallinks"

const useStyles = createUseStyles({
  root: {
    padding: `3.3vmax 4vw`,
    display: `flex`,
  },
  "@media (max-width: 850px)": {
    root: {
      flexDirection: `column`,
    },
  },
})

interface Props {}

const Footer = (props: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div style={{ flexGrow: 2, marginBottom: `3.3vmax` }}>
        <h1
          style={{
            fontSize: `calc((2.2 - 1) * 1.2vh + 1rem)`,
            fontFamily: "middle earth",
            marginBottom: 17,
          }}
        >
          Second Breakfast
        </h1>
        <div
          style={{
            margin: `34px 0`,
          }}
        >
          <SocialLinks isSmall />
        </div>
        <p>
          Made with ♥ and <a href="https://www.gatsbyjs.com/">Gatsby</a>
        </p>
      </div>
      <div style={{ flexGrow: 1, marginBottom: `3.3vmax` }}>
        <p
          style={{
            fontSize: `calc((1.3 - 1) * 1.2vw + 1rem)`,
            lineHeight: 1.6,
          }}
        >
          Leatherwood Mountains
          <br />
          Ferguson, NC 28624
        </p>
      </div>
      <div style={{ flexGrow: 1, marginBottom: `3.3vmax` }}>
        <p
          style={{
            fontSize: `calc((1.3 - 1) * 1.2vw + 1rem)`,
            lineHeight: 1.6,
          }}
        >
          (336) 973-4142
          <br /> rentals@leatherwoodmountains.com
        </p>
      </div>
    </div>
  )
}

export default Footer
