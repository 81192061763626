import React from "react"
import { IoHome, IoLogoInstagram, IoLogoFacebook } from "react-icons/io5"

interface Props {
  isSmall?: boolean
  size?: number
  isOverlay?: boolean
}

const SocialLinks = (props: Props) => {
  return (
    <div className="social" style={{ position: "relative", top: 2 }}>
      <a
        href="https://www.houfy.com/lodging/second-breakfast/19658"
        style={{
          ...(props.isOverlay && { margin: props.isOverlay && `15px 7.5px` }),
          marginLeft: props.isSmall ? `12px` : `1.6vw`,
          color: `inherit`,
        }}
      >
        {<IoHome size={props.size ?? 18} />}
      </a>
      <a
        href="https://www.instagram.com/secondbreakfastcabin"
        style={{
          ...(props.isOverlay && { margin: props.isOverlay && `15px 7.5px` }),
          marginLeft: props.isSmall ? `12px` : `1.6vw`,
          color: `inherit`,
        }}
      >
        {<IoLogoInstagram size={props.size ?? 18} />}
      </a>
      <a
        href="https://www.facebook.com/secondbreakfastcabin"
        style={{
          marginLeft: props.isSmall ? `12px` : `1.6vw`,
          color: `inherit`,
          ...(props.isOverlay && { margin: props.isOverlay && `15px 7.5px` }),
        }}
      >
        {<IoLogoFacebook size={props.size ?? 18} />}
      </a>
    </div>
  )
}

export default SocialLinks
