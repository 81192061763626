/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../css/reset.css"
import "../css/layout.css"
import Footer from "./footer"

interface Props {
  setHeaderHeight?: React.Dispatch<React.SetStateAction<number>>
}

const Layout: React.FC<Props> = ({ setHeaderHeight, children }) => {
  const data = useStaticQuery<GatsbyTypes.SiteTitleQueryQuery>(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        setHeaderHeight={setHeaderHeight}
      />

      {children}

      <Footer />
      {/* <footer
        style={{
          marginTop: `2rem`,
        }}
      >
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </footer> */}
    </>
  )
}

export default Layout
