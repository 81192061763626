import React from "react"
import { createUseStyles } from "react-jss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const useStyles = createUseStyles({
  cta: {
    textDecoration: `none`,
  },
  dark: {
    composes: `$cta`,
    padding: `0.96em 1.6em`,
    backgroundColor: `#9c9a81`,
    color: `white`,
    alignSelf: `flex-start`,
    transition: `opacity 0.1s linear`,
    "&:hover": {
      opacity: 0.8,
    },
  },
  light: {
    composes: `$cta`,
    color: `#9c9a81`,
    padding: `1.2em 2em`,
    backgroundColor: `white`,
    marginTop: `34px`,
    width: `fit-content`,
  },
})

interface Props {
  isLight?: boolean
  marginLeft?: string
}

const Cta = ({ isLight, marginLeft }: Props) => {
  const classes = useStyles()

  return (
    <OutboundLink
      className={isLight ? classes.light : classes.dark}
      style={marginLeft ? { marginLeft } : {}}
      target="_blank"
      href="https://www.leatherwoodmountains.com/vrp/unit/Second_Breakfast-6-15"
    >
      Check Availability / Book Now
    </OutboundLink>
  )
}

export default Cta
